import React from 'react';
import Text from 'componentsShared/Text/Text';
import { string, array, bool, func } from 'prop-types';
import Loader from 'componentsShared/Loader/Loader';
import cn from 'classnames';
import st from './ListComponentCompanyAdmin.module.scss';
import HolderItemComponentCompanyAdmin from '../HolderItemComponentCompanyAdmin/HolderItemComponentCompanyAdmin';

ListComponentCompanyAdmin.propTypes = {
  data: array,
  columns: array,
  actions: array,
  actionsCustomerTable: array,
  classNameRowListWrapper: string,
  isLoading: bool,
  isBackgroundColorGrayLoader: bool,
  searchValue: string,
  placeholderText: string,
  placeholderTextSearch: string,
  classNameRowListHolder: string,
  setShowCustomerCreateOffcanvas: func,
  setSelectCompanyId: func,
};

function ListComponentCompanyAdmin({
  data = [],
  columns = [],
  actions = [],
  actionsCustomerTable = [],
  classNameRowListWrapper,
  isLoading = false,
  isBackgroundColorGrayLoader = false,
  searchValue = '',
  placeholderText = '',
  placeholderTextSearch = '',
  classNameRowListHolder,
  setShowCustomerCreateOffcanvas = () => {},
  setSelectCompanyId = () => {},
}) {
  const renderRowList = () => {
    return data.map((item) => {
      const widthActions = columns?.[columns.length - 1].width || 0;

      return (
        <HolderItemComponentCompanyAdmin
          item={item}
          columns={columns}
          actions={actions}
          actionsCustomerTable={actionsCustomerTable}
          widthActions={widthActions}
          setShowCustomerCreateOffcanvas={setShowCustomerCreateOffcanvas}
          setSelectCompanyId={setSelectCompanyId}
          key={item?.id}
        />
      );
    });
  };

  const renderHeader = () => {
    return columns.map((item) => {
      const width = item.width || 0;
      const isFlexGrow = !!item.flexGrow;
      return (
        <div
          className={cn(
            st.boxContainerHeader,
            { [st.flexGrow]: isFlexGrow },
            item.className
          )}
          key={item.dataKey}
          style={{ minWidth: width, maxWidth: isFlexGrow ? 'auto' : width }}>
          <Text variant='normal' bold>
            {item.title}
          </Text>
        </div>
      );
    });
  };

  const renderPlaceholder = () => {
    return (
      <Text variant='normal' className={st.placeholderText}>
        {searchValue ? placeholderTextSearch : placeholderText}
      </Text>
    );
  };

  return (
    <div>
      <div className={st.headerContainer}>{renderHeader()}</div>
      <div
        className={cn(st.listRowHolder, classNameRowListHolder, {
          [st.holderPlaceholder]: data.length === 0,
        })}>
        <div
          className={cn(st.listRowContainer, classNameRowListWrapper, {
            [st.containerPlaceholder]: data.length === 0,
          })}>
          {data.length > 0 ? renderRowList() : renderPlaceholder()}
        </div>
        <Loader
          isVisible={isLoading}
          isBackgroundColorGray={isBackgroundColorGrayLoader}
        />
      </div>
    </div>
  );
}

export default ListComponentCompanyAdmin;
