import React, { useEffect, useState } from 'react';
import AdminHeaderLayout from 'layouts/AdminHeaderLayout/AdminHeaderLayout';
import { useSelector, useDispatch } from 'react-redux';
import * as customersActions from 'store/customers/actions';
import {
  NO_INFORMATION_FOUND_SEARCH_TEXT,
  TIME_SEARCH_DEBOUNCE,
} from 'constants/ui';
import { EditIcon } from 'assets/icons';
import OffcanvasCustomerEdit from 'components/Offcanvas/OffcanvasCustomerEdit/OffcanvasCustomerEdit';
import { debounce } from 'helpers/functions';
import { ACTION_COLUMN } from 'constants/table';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useLocation } from 'react-router';
import { getQueryVariable } from 'helpers/path';
import ListComponentCompanyAdmin from 'components/Customers/ListComponentCompanyAdmin/ListComponentCompanyAdmin';
import FooterPagination from 'componentsShared/FooterPagination/FooterPagination';
import OffcanvasCompanyEdit from 'components/Offcanvas/OffcanvasCompanyEdit/OffcanvasCompanyEdit';
import OffcanvasCompanyCreate from 'components/Offcanvas/OffcanvasCompanyCreate/OffcanvasCompanyCreate';
import OffcanvasCustomerCreate from 'components/Offcanvas/OffcanvasCustomerCreate/OffcanvasCustomerCreate';
import st from './AdminCustomersPage.module.scss';

const columnTable = [
  { dataKey: 'name', width: 275, title: 'Company', flexGrow: true },
  {
    dataKey: 'representativeName',
    width: 275,
    title: 'Representative Name',
    truncated: true,
  },
  { dataKey: 'email', width: 275, title: 'Email', truncated: true },
  { dataKey: 'status', width: 200, title: 'Status', truncated: true },
  { dataKey: ACTION_COLUMN, width: 100, title: '' },
];

const debouncedSearch = debounce((onSearch, data) => {
  onSearch(data);
}, TIME_SEARCH_DEBOUNCE);

function AdminCustomersPage() {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryVariable = getQueryVariable(location.search);
  const currentPage = queryVariable.page || 0;

  const [showCompanyOffcanvas, setShowCompanyOffcanvas] = useState(false);
  const [showCustomerEditOffcanvas, setShowCustomerEditOffcanvas] =
    useState(false);
  const [showCustomerCreateOffcanvas, setShowCustomerCreateOffcanvas] =
    useState(false);

  const [selectCustomerId, setSelectCustomerId] = useState();
  const [selectCompanyId, setSelectCompanyId] = useState();

  const isLoadingCustomer =
    useSelector((state) => state?.customers?.customers?.isLoading) ?? false;
  const isLoadingCreateCustomer =
    useSelector((state) => state?.customers?.customers?.isLoadingCreate) ??
    false;
  const isLoadingChangeCustomer =
    useSelector((state) => state?.customers?.customers?.isLoadingChange) ??
    false;
  const isLoadingResendInvite =
    useSelector(
      (state) => state?.customers?.customers?.isLoadingResendInvite
    ) ?? false;

  const customerList =
    useSelector((state) => state?.customers?.customers?.data) ?? {};
  const customerListArray = Object.values(customerList);

  const searchValue =
    useSelector((state) => state?.customers?.fetchParams?.search) ?? '';
  const isLoadingSearch =
    useSelector((state) => state?.customers?.fetchParams?.isLoading) ?? false;
  const paginationObj =
    useSelector((state) => state?.customers?.fetchParams?.pagination) ?? {};

  const isLoading =
    isLoadingCustomer ||
    isLoadingCreateCustomer ||
    isLoadingChangeCustomer ||
    isLoadingResendInvite;

  const onChangeFilterSearch = (value) => {
    dispatch(customersActions.changeFilterSearch(value));
  };

  const loadCustomerList = (isClearPage) => {
    dispatch(
      customersActions.getCustomerList.start({
        filter: searchValue,
        isPagination: true,
        isClearPage,
      })
    );
  };

  const handleChangeSearch = (value) => {
    onChangeFilterSearch(value);
    if (value) {
      return debouncedSearch(() => {
        loadCustomerList(true);
      });
    }
    loadCustomerList(true);
  };

  const actionsTable = [
    {
      icon: <EditIcon className={st.editIcon} />,
      onClick: (rowData) => {
        setSelectCompanyId(rowData.id);
        setShowCompanyOffcanvas(true);
      },
    },
  ];

  const actionsCustomerTable = [
    {
      icon: <EditIcon className={st.editIcon} />,
      onClick: (rowData) => {
        setSelectCompanyId(rowData.companyId);
        setSelectCustomerId(rowData.id);
        setShowCustomerEditOffcanvas(true);
      },
    },
  ];

  const handleChangePage = (page) => {
    dispatch(customersActions.changePage(page));
    loadCustomerList();
  };

  useEffect(() => {
    if (!+currentPage) {
      onChangeFilterSearch('');
    }
    handleChangePage(+currentPage);

    return () => {
      onChangeFilterSearch('');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <AdminHeaderLayout
      title='Customers'
      classNameContentHolder={st.layoutContentHolder}
      searchValue={searchValue}
      handleChangeSearch={handleChangeSearch}
      isLoadingSearch={isLoadingSearch}
      placeholderSearch='Search by email, company'
      inviteButton={() => <OffcanvasCompanyCreate />}
      renderFooter={() => (
        <FooterPagination
          page={paginationObj.page}
          countItem={paginationObj.count}
          size={paginationObj.size}
          onChange={handleChangePage}
          isFullLine
          isShowPlaceholder={customerListArray.length === 0}
        />
      )}>
      <ListComponentCompanyAdmin
        data={customerListArray}
        columns={columnTable}
        actions={actionsTable}
        actionsCustomerTable={actionsCustomerTable}
        classNameRowListWrapper={st.rowListWrapper}
        classNameRowListHolder={st.rowListHolder}
        isLoading={isLoading}
        placeholderText={NO_INFORMATION_FOUND_SEARCH_TEXT}
        setShowCustomerCreateOffcanvas={setShowCustomerCreateOffcanvas}
        setSelectCompanyId={setSelectCompanyId}
      />

      {selectCompanyId && (
        <OffcanvasCompanyEdit
          company={customerList?.[selectCompanyId]}
          showOffcanvas={showCompanyOffcanvas}
          setShowOffcanvas={setShowCompanyOffcanvas}
          setSelectCompanyId={setSelectCompanyId}
        />
      )}

      <OffcanvasCustomerCreate
        companyId={selectCompanyId}
        showOffcanvas={showCustomerCreateOffcanvas}
        setShowOffcanvas={setShowCustomerCreateOffcanvas}
        setSelectCompanyId={setSelectCompanyId}
      />

      {selectCustomerId && selectCompanyId && (
        <OffcanvasCustomerEdit
          companyId={selectCompanyId}
          customer={customerList?.[selectCompanyId]?.logins?.find(({ id }) => {
            return +id === +selectCustomerId;
          })}
          showOffcanvas={showCustomerEditOffcanvas}
          setShowOffcanvas={setShowCustomerEditOffcanvas}
          setSelectCustomerId={setSelectCustomerId}
          setSelectCompanyId={setSelectCompanyId}
        />
      )}
    </AdminHeaderLayout>
  );
}

export default AdminCustomersPage;
