import React, { useEffect, useRef, useState } from 'react';
import { FormControl, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { MAX_LENGTH_INPUT, VARIANT_OUTLINE_UI, VARIANT_UI } from 'constants/ui';
import autosize from 'autosize';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useSelector, useDispatch } from 'react-redux';
import * as travelersActions from 'store/travelers/actions';
import st from './InputOperation.module.scss';

InputOperation.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValue: PropTypes.string,
  titleAcceptBtn: PropTypes.string,
  titleCancelBtn: PropTypes.string,
};

function InputOperation({
  handleSubmit,
  initialValue = '',
  titleAcceptBtn = 'Accept',
  titleCancelBtn = 'Cancel',
  phaseType = '',
}) {
  const dispatch = useDispatch();

  const suggestOperation =
    useSelector((state) => state?.travelers?.suggest?.operation?.[phaseType]) ??
    [];
  const [selected, setSelected] = useState([{ label: initialValue }]);
  const [value, setValue] = useState(initialValue);
  const inputId = 'input-operation-traveler-id';
  const typeaheadId = 'typeahead-operation-traveler-id';

  const textareaRef = useRef(null);
  const options = suggestOperation.map((item) => ({ label: item })) || [];

  const handleChange = (selectedValue) => {
    setSelected(selectedValue);
    if (selectedValue?.[0]?.label) {
      setValue(selectedValue?.[0]?.label || '');
      setTimeout(() => autosize.update(document.getElementById(inputId)), 0);
    }
  };

  const onSubmit = () => {
    const currentValue = selected?.[0]?.label || value;
    handleSubmit(currentValue);
  };

  useEffect(() => {
    if (phaseType) {
      dispatch(
        travelersActions.getSuggestOperationByPhase.start({ phase: phaseType })
      );
    }
  }, [dispatch, phaseType]);

  useEffect(() => {
    const element = document.getElementById(inputId);
    if (element) {
      autosize(element);
    }
  }, []);

  return (
    <div className={st.container}>
      <Typeahead
        options={options}
        selected={selected}
        onChange={handleChange}
        id={typeaheadId}
        renderInput={({
          inputRef,
          referenceElementRef,
          onChange,
          ...inputProps
        }) => (
          <FormControl
            {...inputProps}
            ref={(input) => {
              inputRef(input);
              referenceElementRef(input);
              textareaRef.current = input;
            }}
            rows={1}
            as='textarea'
            size='sm'
            value={value}
            maxLength={MAX_LENGTH_INPUT}
            onChange={(e) => {
              onChange(e);
              setValue(e.target.value);
            }}
            id={inputId}
          />
        )}
      />

      <Button
        variant={value ? VARIANT_UI.primary : VARIANT_OUTLINE_UI.primary}
        className={st.buttonEditDone}
        onClick={onSubmit}
        size='sm'>
        {value ? titleAcceptBtn : titleCancelBtn}
      </Button>
    </div>
  );
}

export default InputOperation;
