import CenteredColumnLayout from 'layouts/CenteredColumnLayout/CenteredColumnLayout';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HeaderContent from 'componentsShared/HeaderContent/HeaderContent';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ROUTES } from 'router/routes';
import NavigationService from 'services/navigation/NavigationService';
import { Button } from 'react-bootstrap';
import * as ordersActions from 'store/orders/actions';
import { debounce } from 'helpers/functions';
import InputSearch from 'componentsShared/InputSearch/InputSearch';
import {
  EMPTY_FOUND_PO_CUSTOMER_ROLE_TEXT,
  EMPTY_PO_CUSTOMER_ROLE_TEXT,
  IS_PHONE,
  TIME_SEARCH_DEBOUNCE,
  VARIANT_OUTLINE_UI,
  VARIANT_UI,
} from 'constants/ui';
import Loader from 'componentsShared/Loader/Loader';
import FooterPagination from 'componentsShared/FooterPagination/FooterPagination';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useLocation } from 'react-router';
import useMediaQuery from 'hooks/useMediaQuery';
import { getQueryParam, getQueryVariable, getRoutePath } from 'helpers/path';
import cn from 'classnames';
import OrderListCustomerWrapper from 'components/Order/OrderListCustomerWrapper/OrderListCustomerWrapper';
import { ORDER_STATUS } from 'constants/order';
import st from './POListCustomerPage.module.scss';

const debouncedSearch = debounce((onSearch, data) => {
  onSearch(data);
}, TIME_SEARCH_DEBOUNCE);

function POListCustomerPage() {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryVariable = getQueryVariable(location.search);
  const currentType = queryVariable.type;

  const currentPage = queryVariable.page || 0;
  const isPhone = useMediaQuery(IS_PHONE);

  const isLoadingOrders =
    useSelector((state) => state?.orders?.orders?.isLoading) ?? false;
  const batchList = useSelector((state) => state?.orders?.orders?.data) ?? {};
  const batchIds = useSelector((state) => state?.orders?.orders?.sortIds) ?? [];
  const searchValue =
    useSelector((state) => state?.orders?.fetchParams?.search) ?? '';
  const isLoadingSearch =
    useSelector((state) => state?.orders?.fetchParams?.isLoading) ?? false;
  const paginationObj =
    useSelector((state) => state?.orders?.fetchParams?.pagination) ?? {};
  const showDataOrderAndJob =
    useSelector((state) => state?.orders?.dataOrderAndJob) ?? '';
  const isScrollJob =
    useSelector((state) => state?.orders?.isScrollJob) ?? false;
  const isSearchClient =
    useSelector((state) => state?.orders?.fetchParams?.isSearchClient) ?? false;

  const [typeOrder, setTypeOrder] = useState(currentType);

  const [isScrollJobState, setIsScrollJobState] = useState(false);

  const handleCreateOrder = () => {
    NavigationService.navigateToPath(ROUTES.createOrder.path);
  };

  const loadPOList = (isClearPage, status = '') => {
    dispatch(
      ordersActions.getOrdersList.start({
        params: { filter: searchValue },
        showLoader: true,
        isClient: true,
        isClearPage,
        isPagination: true,
        status: status || typeOrder,
      })
    );
  };

  const onChangeFilterSearch = (value) => {
    dispatch(ordersActions.changeFilterSearch(value));
  };

  const handleChangeSearch = (value) => {
    onChangeFilterSearch(value);
    if (value) {
      return debouncedSearch(() => {
        loadPOList(true);
      });
    }
    loadPOList(true);
  };

  const handleChangePage = (page) => {
    dispatch(ordersActions.changePage(page));
    loadPOList();
  };

  const handleClickJobCard = (jobId, orderId) => {
    dispatch(
      ordersActions.changeDataOrderAndJob(
        `${jobId}-${orderId}-${paginationObj.page}`
      )
    );

    NavigationService.navigateToPath(
      getRoutePath(ROUTES.orderDetailsHistory.path, {
        jobId,
      })
    );
  };

  const renderInputSearch = () => {
    return (
      <InputSearch
        value={searchValue}
        onChange={handleChangeSearch}
        isLoadingSearch={isLoadingSearch}
        placeholder='Search by PO#, Part'
        className={isPhone ? st.inputSearchPhone : undefined}
      />
    );
  };

  const renderButtonActive = () => {
    if (!isSearchClient) {
      return null;
    }

    return (
      <div
        className={cn(st.holderButtonActive, {
          [st.holderButtonActivePhone]: isPhone,
        })}>
        <Button
          variant={VARIANT_OUTLINE_UI.secondary}
          size='sm'
          onClick={() => dispatch(ordersActions.orderExpandAll())}
          disabled={
            batchIds.length === 0 || isLoadingOrders || isLoadingSearch
          }>
          Expand All
        </Button>
        <Button
          variant={VARIANT_OUTLINE_UI.secondary}
          size='sm'
          onClick={() => dispatch(ordersActions.orderCollapseAll())}
          disabled={
            batchIds.length === 0 || isLoadingOrders || isLoadingSearch
          }>
          Collapse All
        </Button>
      </div>
    );
  };

  useEffect(() => {
    if (!+currentPage) {
      onChangeFilterSearch('');
    }

    const newTypeOrder = currentType || ORDER_STATUS.ACTIVE;
    setTypeOrder(newTypeOrder);
    const queryParams = {
      ...queryVariable,
      page: currentPage,
      type: newTypeOrder,
    };
    dispatch(ordersActions.changePage(currentPage));
    loadPOList(false, newTypeOrder);
    const queryParamsString = getQueryParam(queryParams);
    const path = `${location.pathname}${queryParamsString}`;

    NavigationService.replacePath(path);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    const isShowJob =
      showDataOrderAndJob &&
      isScrollJob &&
      !isLoadingSearch &&
      !isLoadingOrders &&
      typeof paginationObj.page === 'number';

    if (isShowJob) {
      const [jobId, order, page] = showDataOrderAndJob.split('-');

      if (page.toString() === paginationObj.page.toString()) {
        setTimeout(() => {
          const currentId =
            jobId === ORDER_STATUS.DRAFT
              ? `order-card-${order}`
              : `job-card-${jobId}`;

          const element = document.getElementById(currentId);

          if (element) {
            element.scrollIntoView({
              block: 'center',
              behavior: 'smooth',
            });
          }
        }, 1000);
      } else {
        const countPages = +Math.ceil(paginationObj.count / paginationObj.size);
        if (+page > countPages - 1) {
          dispatch(ordersActions.changeIsScrollJob());
        } else {
          handleChangePage(page);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    showDataOrderAndJob,
    isScrollJob,
    isLoadingOrders,
    isLoadingSearch,
    paginationObj.page,
    batchList,
  ]);

  useEffect(() => {
    if (isScrollJob) {
      setIsScrollJobState(true);
      dispatch(ordersActions.changeIsScrollJob());
    }
  }, [isScrollJob, dispatch]);

  const footer = (
    <FooterPagination
      page={paginationObj.page}
      countItem={paginationObj.count}
      size={paginationObj.size}
      onChange={handleChangePage}
      className={st.footerWrapper}
      classNameLine={st.footerLine}
      isShowPlaceholder={batchIds.length === 0}
      placeholder={<div className={st.placeholderFooter} />}
    />
  );

  return (
    <CenteredColumnLayout
      classNamePageContentHolder={cn({
        [st.classNamePageContentHolder]: isSearchClient,
      })}
      classNamePageWrapper={cn({ [st.layoutPageWrapper]: isSearchClient })}
      renderFooter={() => (isSearchClient ? footer : null)}>
      <HeaderContent
        title='Purchase Orders'
        isActive
        flexGrowNone
        componentActive={() =>
          isPhone ? renderButtonActive() : renderInputSearch()
        }
        className={st.holderHeader}>
        <Button
          variant={VARIANT_UI.primary}
          onClick={handleCreateOrder}
          size={isPhone ? 'sm' : 'md'}>
          New PO
        </Button>
      </HeaderContent>
      {isPhone ? renderInputSearch() : renderButtonActive()}
      <div className={st.containerList}>
        <div
          className={cn(st.contentList, {
            [st.contentListWithoutSearch]: !isSearchClient,
          })}>
          <OrderListCustomerWrapper
            dataList={batchList}
            classNameList={st.orderList}
            ids={batchIds}
            isCustomer
            handleClickJobCard={handleClickJobCard}
            emptyText={EMPTY_PO_CUSTOMER_ROLE_TEXT}
            searchEmptyText={EMPTY_FOUND_PO_CUSTOMER_ROLE_TEXT}
            isSearch={!!searchValue}
            showDataOrderAndJob={isScrollJobState ? showDataOrderAndJob : ''}
            page={paginationObj.page}
            footer={footer}
            loadPOList={loadPOList}
            type={typeOrder}
            setType={setTypeOrder}
            isSearchClient={isSearchClient}
            isLoading={isLoadingSearch}
          />
        </div>

        <Loader
          isVisible={isLoadingOrders}
          isBackgroundColorGray
          className={cn({ [st.loaderWrapper]: isSearchClient })}
        />
      </div>
    </CenteredColumnLayout>
  );
}

export default POListCustomerPage;
